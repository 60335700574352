import { Component, Prop, Mixins } from '@zento-lib/components';

import { FormField } from './FormField';
import { ValidatorOneOf } from './types';
import { ValidationMechanisms } from './validation/mechanisms';
import { ValidationMessage } from './Message';
import { Checkbox } from './Checkbox';
import { InputPropagationEventType } from './Input';
import type { IRadioGroup } from './RadioGroup.d';
import style from './style.scss';

export interface IItem {
  value: string | number;
  label: string;
  secondLabel: string;
  disabled?: boolean;
}

export type RadioGroupItem = IItem;

@Component({})
export class RadioGroup extends Mixins<FormField<string, InputPropagationEventType, IRadioGroup, any>>(FormField) {
  /**
   * Check if the validator oneOf exists in the context of current field instance
   */
  protected static validatorOneOfExtraction(instance: RadioGroup): ValidatorOneOf<any> | null {
    // Compute the parameters
    return {
      value: instance.items,
      labelKeeper: 'label',
      valueKeeper: 'value',
    };
  }

  /**
   * Record of validation rule names to validation parameters extraction functions
   */
  protected validationRules = {
    [FormField.RequiredRuleName]: FormField.validatorRequiredExtraction,
    oneOf: RadioGroup.validatorOneOfExtraction,
  };

  protected validationMechanisms = {
    [FormField.RequiredRuleName]: ValidationMechanisms.string.required,
    oneOf: ValidationMechanisms.string.oneOf,
  };

  /**
   * Group unique name seed
   */
  private seed = Math.floor(Math.random() * 1000);

  /**
   * Input type property
   */
  @Prop({ type: String, default: 'radio' }) type: 'checkbox' | 'radio';

  /**
   * Items property
   */
  @Prop({ type: Array, default: () => [] }) items: IItem[];

  data() {
    const value = this.getFieldValue(); // Don't use cached values

    return {
      value,
      ...this.hydrateItemsState(value), // Allow selecting provided value,
      extractedRules: {},
    };
  }

  beforeMount() {
    this.items.forEach((item) => {
      this.unwatch.push(
        this.$watch(item.value.toString(), (newValue: boolean, oldValue: boolean) => {
          const current = item.value;
          const last = this.$data.value;
          // let found = false;

          // Invalidate previous selection
          if (last !== current) {
            this.$data[current] = newValue;
            this.$data[last] = oldValue;
            this.$data.value = newValue ? current : last;

            this.propagateValue('change');

            // Propagate event to other interested parties
            this.propagateEvent({
              type: 'change',
              target: { value: this.$data.value },
            } as any);
          }
        }),
      );
    });
  }

  private hydrateItemsState(last) {
    return this.items
      .map((item) => item.value)
      .reduce((ret, i) => {
        ret[i] = i === last;

        return ret;
      }, {} as Record<string | number, boolean>);
  }

  /**
   * Computed name getter
   * Ensures group's uniqueness
   */
  private get computedName() {
    return `${this.name}_${this.seed}[]`;
  }

  /**
   * Remove special characters for rendering correct value
   */
  private removeSpecialChars(value, char) {
    const fieldValue = value.replace(/[^a-zA-Z0-9]/g, char);

    return fieldValue;
  }

  public render() {
    return (
      <ValidationMessage type='string' state={this.validationState} validationParams={this.rules}>
        <div class={style.radioCards}>
          {this.items.map((item) => {
            return (
              <Checkbox
                state={this.$data}
                valueKeeper={item.value.toString()}
                name={this.computedName}
                type={this.type}
                key={item.value}
                label={item.label}
                embedded={true}
                pristinePolicy={this.pristinePolicy}
                secondLabel={item.secondLabel}
                disabled={item.disabled}
                dataTestId={this.removeSpecialChars(item.label, '-')}
                class={style.radioItem}
              />
            );
          })}
        </div>
      </ValidationMessage>
    );
  }

  protected isPristine() {
    const isRequired = this.activeRules[0] === FormField.RequiredRuleName;
    const pristineInParent = super.isPristine();

    return isRequired
      ? pristineInParent
        ? `${this.fieldValue}`.length !== 0
          ? false // Consider preselected radio groups as non pristine when required
          : pristineInParent
        : pristineInParent
      : pristineInParent;
  }
}
